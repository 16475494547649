<template>
  <div class="main">
    <div class="booth">
      <div class="inner">
        <a href="#" class="attractions">
          <a href="#" class="link-item" :id="`attractions-${theID}`" @click="showAttr"><img src="../assets/images/attraction-link.png"></a>
          <a href="#" class="link-item" :id="`maps-${theID}`" @click="showMapsModal('maps')"><img src="../assets/images/maps-link.png"></a>
        </a>
        <!-- <a href="#" class="pass">
          <img src="../assets/images/download.png">
        </a> -->
        <a href="#" class="video link-item" :id="`video-${theID}`" @click="showModal">
          <img src="../assets/images/thumb.png">
        </a>
        <!-- <a href="#" class="app">
          <img src="../assets/images/download-app.png">
        </a> -->
        <a href="#" @click="showLogo" :id="`logo-${theID}`" class="link-item">
          <img src="../assets/images/logo.png" class="logo" />
        </a>
        <a href="#" target="_blank" class="brochures link-item" :id="`brochures-${theID}`" @click="showFormModal('itin')">
          <img src="../assets/images/brochures.png">
        </a>
        <a href="#" class="itineraries link-item" :id="`itineraries-${theID}`" @click="showPDFModal('itin')">
          <img src="../assets/images/itineraries.png">
        </a>
        <img src="../assets/images/booth.png"/>
      </div>
      <div class="modal video" id="videoModal">
        <div class="inner">
          <vue-plyr id="player" ref="plyr">
           <video controls crossorigin playsinline poster="../assets/images/poster.jpg">
             <source src="../assets/video/MM2.0-Female-New-End-Card.mp4" type="video/mp4" />
           </video>
         </vue-plyr>
       </div>
       <span class="close-button" @click="hideModal()">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.41 29.41">
          <path d="M4.31,4.31a14.71,14.71,0,1,0,20.8,0A14.7,14.7,0,0,0,4.31,4.31ZM20,11.63,17,14.7l3.09,3.08-2.34,2.34L14.63,17l-3.09,3.09L9.21,17.78l3.08-3.08L9.23,11.63,11.56,9.3l3.07,3.06L17.69,9.3Z" style="fill:#ffffff"/>
        </svg>
      </span>
    </div>
    <div class="modal content" id="formModal">
      <div class="submitting" v-if="submitting">
        <img src="../assets/images/spinner.gif" />
      </div>
      <div class="inner form">
        <h2>Ready. Set. Explore with Your Digital Brochure!</h2>
        <p>Find the best Utah’s Canyon Country has to offer — from the iconic to the hidden gems. Fill out the form below for your own digital copy.</p>
        <form @submit.prevent="formSubmit" v-if="!success">
          <div>
            <input type="text" id="FirstName" name="FirstName" v-model="FirstName" placeholder="First Name" :class="{ 'hasError': $v.FirstName.$error }">
            <span class="error-message" v-if="$v.FirstName.$error">This field is required.</span>
          </div>
          <div>
            <input type="text" id="LastName" name="LastName" v-model="LastName" placeholder="Last Name" :class="{ 'hasError': $v.LastName.$error }">
            <span class="error-message" v-if="$v.LastName.$error">This field is required.</span>
          </div>
          <div>
            <input type="email" id="Email" name="Email" v-model="Email" placeholder="Email" :class="{ 'hasError': $v.Email.$error }">
            <div v-if="submitted && $v.Email.$error" class="invalid-feedback">
              <span class="error-message" v-if="!$v.Email.required">This field is required.</span>
              <span class="error-message" v-if="!$v.Email.email">Please include a valid email adddress</span>
            </div>
          </div>
          <div>
            <button type="submit" class="button">Submit</button>
          </div>
        </form>
        <div v-if="thankyou">
          <p>Thank you!</p>
        </div>
      </div>
      <span class="close-button" @click="hideModal()">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.41 29.41">
          <path d="M4.31,4.31a14.71,14.71,0,1,0,20.8,0A14.7,14.7,0,0,0,4.31,4.31ZM20,11.63,17,14.7l3.09,3.08-2.34,2.34L14.63,17l-3.09,3.09L9.21,17.78l3.08-3.08L9.23,11.63,11.56,9.3l3.07,3.06L17.69,9.3Z" style="fill:#ffffff"/>
        </svg>
      </span>
    </div>
    <div class="modal content" id="mapsModal">
      <div class="inner">
        <div class="list">
          <ul v-if="showMaps">
            <li><router-link :to="{name: 'PDF', params: { val: 'monticello'}}">Monticello Map</router-link></li>
            <li><router-link :to="{name: 'PDF', params: { val: 'blanding'}}">Blanding Map</router-link></li>
            <li><router-link :to="{name: 'PDF', params: { val: 'bluff'}}">Bluff Map</router-link></li>
            <li><router-link :to="{name: 'PDF', params: { val: 'seedo'}}">See & Do</router-link></li>
          </ul>
        </div>
      </div>
      <span class="close-button" @click="hideModal()">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.41 29.41">
          <path d="M4.31,4.31a14.71,14.71,0,1,0,20.8,0A14.7,14.7,0,0,0,4.31,4.31ZM20,11.63,17,14.7l3.09,3.08-2.34,2.34L14.63,17l-3.09,3.09L9.21,17.78l3.08-3.08L9.23,11.63,11.56,9.3l3.07,3.06L17.69,9.3Z" style="fill:#ffffff"/>
        </svg>
      </span>
    </div>
    <div class="modal content" id="pdfModal">
      <div class="inner">
        <div class="list">
          <ul v-if="showItin">
            <li><router-link :to="{name: 'PDF', params: { val: 'spi'}}">Mighty 5/Southern Utah National Parks Itinerary</router-link></li>
            <li><router-link :to="{name: 'PDF', params: { val: 'ffg'}}">Family Friendly Guide</router-link></li>
            <li><router-link :to="{name: 'PDF', params: { val: 'ag'}}">Adventure Guide</router-link></li>
            <li><router-link :to="{name: 'PDF', params: { val: 'bli'}}">Bucket-List Itinerary</router-link></li>
            <li><router-link :to="{name: 'PDF', params: { val: 'rti'}}">2-Day Road Trip Itinerary</router-link></li>
            <li><router-link :to="{name: 'PDF', params: { val: 'benm'}}">A Beginner’s Guide to Bears Ears National Monument: A 3-Day Iinerary</router-link></li>
            <li><router-link :to="{name: 'PDF', params: { val: 'hi'}}">Hiking Itinerary</router-link></li>
            <li><router-link :to="{name: 'PDF', params: { val: 'ita'}}">In Town Activities Itinerary</router-link></li>
            <li><router-link :to="{name: 'PDF', params: { val: 'la'}}">Local Arts and Trading Posts Itinerary</router-link></li>
            <li><router-link :to="{name: 'PDF', params: { val: 'mi'}}">Monolith Itinerary</router-link></li>
            <li><router-link :to="{name: 'PDF', params: { val: 'ph'}}">Pioneer History Itinerary</router-link></li>
            <li><router-link :to="{name: 'PDF', params: { val: 'ai'}}">2-Day Trail of the Ancients Itinerary</router-link></li>
          </ul>
        </div>
      </div>
      <span class="close-button" @click="hideModal()">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.41 29.41">
          <path d="M4.31,4.31a14.71,14.71,0,1,0,20.8,0A14.7,14.7,0,0,0,4.31,4.31ZM20,11.63,17,14.7l3.09,3.08-2.34,2.34L14.63,17l-3.09,3.09L9.21,17.78l3.08-3.08L9.23,11.63,11.56,9.3l3.07,3.06L17.69,9.3Z" style="fill:#ffffff"/>
        </svg>
      </span>
    </div>
    <div class="modal content iframe" id="attrModal">
      <div class="inner">
        <iframe src="https://www.utahscanyoncountry.com/attractions" scrolling="yes" width="100%;" height="100%;" frameborder="none"></iframe>
      </div>
      <span class="close-button" @click="hideModal()">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.41 29.41">
          <path d="M4.31,4.31a14.71,14.71,0,1,0,20.8,0A14.7,14.7,0,0,0,4.31,4.31ZM20,11.63,17,14.7l3.09,3.08-2.34,2.34L14.63,17l-3.09,3.09L9.21,17.78l3.08-3.08L9.23,11.63,11.56,9.3l3.07,3.06L17.69,9.3Z" style="fill:#ffffff"/>
        </svg>
      </span>
    </div>
    <div class="modal content iframe" id="logoModal">
      <div class="inner">
        <iframe src="https://www.utahscanyoncountry.com" scrolling="yes" width="100%;" height="100%;" frameborder="none"></iframe>
      </div>
      <span class="close-button" @click="hideModal()">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.41 29.41">
          <path d="M4.31,4.31a14.71,14.71,0,1,0,20.8,0A14.7,14.7,0,0,0,4.31,4.31ZM20,11.63,17,14.7l3.09,3.08-2.34,2.34L14.63,17l-3.09,3.09L9.21,17.78l3.08-3.08L9.23,11.63,11.56,9.3l3.07,3.06L17.69,9.3Z" style="fill:#ffffff"/>
        </svg>
      </span>
    </div>
    <div class="overlay" id="ol" @click="hideModal()"></div>
  </div>
</div>
</template>

<script>
  import { required, email } from 'vuelidate/lib/validators';

  export default {
    name: 'Main',
    data() {
      return {
        isIdle: false,
        showItin: false,
        showMaps: false,
        thankyou: false,
        options: {
          fullscreen: {
            enabled: false
          }
        },
        success: false,
        submitted: false,
        FirstName: '',
        LastName: '',
        Email: '',
        submitting: false
      }
    },
    validations: {
      FirstName: { required },
      LastName: { required },
      Email: { required, email }
    },
    computed: {
      theID() {
        return process.env.VUE_APP_ID
      }
    },
    methods: {
      showModal() {
        let modal = document.getElementById('videoModal')
        let overlay = document.getElementById('ol')
        modal.classList.add('show')
        overlay.classList.add('show')
        event.preventDefault()
      },
      showPDFModal(param) {
        let modal = document.getElementById('pdfModal')
        let overlay = document.getElementById('ol')
        modal.classList.add('show')
        overlay.classList.add('show')
        if (param === 'itin') {
          this.showItin = true;
        }
        event.preventDefault()
      },
      showFormModal() {
        let modal = document.getElementById('formModal')
        let overlay = document.getElementById('ol')
        modal.classList.add('show')
        overlay.classList.add('show')
        event.preventDefault()
      },
      showAttr() {
        let modal = document.getElementById('attrModal')
        let overlay = document.getElementById('ol')
        modal.classList.add('show')
        overlay.classList.add('show')
        event.preventDefault()
      },
      showMapsModal(param) {
        let modal = document.getElementById('mapsModal')
        let overlay = document.getElementById('ol')
        modal.classList.add('show')
        overlay.classList.add('show')
        if (param === 'maps') {
          this.showMaps = true;
        }
        event.preventDefault()
      },
      showLogo() {
        let modal = document.getElementById('logoModal')
        let overlay = document.getElementById('ol')
        modal.classList.add('show')
        overlay.classList.add('show')
        event.preventDefault()
      },
      hideModal() {
        let modal = document.getElementById('videoModal')
        let pmodal = document.getElementById('pdfModal')
        let fmodal = document.getElementById('formModal')
        let mmodal = document.getElementById('mapsModal')
        let attr = document.getElementById('attrModal')
        let logo = document.getElementById('logoModal')
        let overlay = document.getElementById('ol')
        modal.classList.remove('show')
        pmodal.classList.remove('show')
        fmodal.classList.remove('show')
        mmodal.classList.remove('show')
        attr.classList.remove('show')
        logo.classList.remove('show')
        overlay.classList.remove('show')
        this.pauseVideo()
      },
      pauseVideo() {
        setTimeout(() => {
          this.$refs.plyr.player.pause()     
        }, 100);
      },
      formSubmit(e) {
        this.submitted = true;
        this.$v.$touch();
        if (this.$v.$error) return;
        this.submitting = true;
        e.preventDefault();
        const currentObj = this;
        const options = {
          headers: {'autopilotapikey': 'b25fb1b953f14e1ba8937873e0b4901f', 'Content-Type': 'application/json'}
        }
        this.axios.post('https://api2.autopilothq.com/v1/contact', {
          "contact": {
            "FirstName": this.FirstName,
            "LastName": this.LastName,
            "Email": this.Email,
            "_autopilot_list": "contactlist_352b5346-80f8-4a63-8b8a-e42314829930"
          }
        }, options)
        .then((response) => {
          console.log(response)
          this.submitting = false;
          this.success = true;
          this.thankyou = true;
        }).catch((error) => {
          currentObj.output = error;
        });
      },
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.main {
  background-image: url('../assets/images/page-bg.jpg');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
}
.booth {
  width: 800px;
  height: 100%;
  margin: 0 auto;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @media screen and (min-width: 1020px) {
    width: 1020px;
  } 

  .inner {
    position: relative;
    text-align: center;

    .social {
      position: absolute;
      top: 90px;
      margin: 0 auto;
      left: 0;
      right: 0;

      @media screen and (min-width: 1020px) {
        top: 120px;  
      }

      ul {
        text-align: center;
        margin: 0;
        padding: 0;

        li {
          display: inline-block;
          margin-right: 10px;

          a {
            display: block; 
            width: 38px;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    .logo {
      position: absolute;
      width: 180px;
      top: 329px;
      left: 310px;

      @media screen and (min-width: 1020px) {
        width: 265px;
        top: 420px;
        left: 383px;  
      }
    }

    .video {
      position: absolute;
      top: 142px;
      left: 294px;
      width: 219px;

      @media screen and (min-width: 1020px) {
        top: 182px;
        left: 377px;
        width: 279px;
      }

      img {
        width: 100%;
      }
    }

    .attractions {
      position: absolute;
      top: 127px;
      left: 86px;
      width: 113px;
      height: 100%;
      background-image: url("../assets/images/attractions.png");
      background-size: contain;
      background-repeat: no-repeat;
      padding-top: 135px;

      @media screen and (min-width: 1020px) {
        top: 162px;
        left: 110px;
        width: 144px;
      }

      img {
        width: 100%;
      }

      a {
        width: 80%;
        display: block;
        margin: 0 auto;

        &:first-child {
          margin-bottom: 15px;
        }
      }
    }

    .brochures {
      position: absolute;
      width: 115px;
      left: 599px;
      top: 128px;

      @media screen and (min-width: 1020px) {
        width: 145px;
        left: 768px;
        top: 164px;
      }

      img {
        width: 100%;
      }
    }

    .itineraries {
      position: absolute;
      width: 115px;
      left: 599px;
      top: 265px;

      @media screen and (min-width: 1020px) {
        width: 145px;
        left: 768px;
        top: 337px;
      }

      img {
        width: 100%;
      }
    }

    .pass {
      position: absolute;
      width: 80px;
      left: 205px;
      top: 123px;

      @media screen and (min-width: 1020px) {
        width: 110px;
        left: 260px;
        top: 157px;
      }

      img {
        width: 100%;
      }
    }

    .app {
      position: absolute;
      width: 77px;
      left: 518px;
      top: 123px;

      @media screen and (min-width: 1020px) {
        width: 100px;
        left: 661px;
        top: 157px;
      }

      img {
        width: 100%;
      }
    }
  }

  img {
    max-width: 100%;
    height: auto;
    object-fit: contain;
  }
}

.modal {
  position: fixed;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
  width: 100%;
  max-width: 800px;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  visibility: hidden;


  &.show {
    z-index: 99;
    opacity: 1;
    visibility: visible;
  }

  .inner {
    width: 100%;
    position: relative;
    padding-bottom: 56.25%;

    &.form {
      padding-left: 5vw;
      padding-right: 5vw;
    }
  }

  .thumbnail {
    position: absolute;
    top: 0;
    left: 0;
  }

  &.content {
    background-color: #fff;

    .inner {
      padding-bottom: 20px;
      padding-top: 20px;
    }
  }

  &.iframe {
    width: 90%;
    height: 90%;
    max-width: 100%;
    top: 6%;

    .inner {
      width: 100%;
      height: 100%;
      position: relative;
      padding-bottom: 0;
      padding-top: 0;
    }
  }
}
video {
  width: 100%;
  height: auto;
}
.close-button {
  position: absolute;
  top: -40px;
  right: 0;
  color: #fff;
  font-size: 21px;
  cursor: pointer;
  svg {
    width: 28px;
  }
}
.overlay {
  position: fixed;
  width: 100%;
  height: 0;
  top: 0;
  left: 0;
  opacity: 0;
  background-color: #000;
  z-index: -2;
  transition: opacity 0.2s ease-in-out;
  visibility: hidden;

  &.show {
    height: 100%;
    z-index: 98;
    opacity: 0.9;
    visibility: visible;
  }
}

.list {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: block;
      text-align: center;

      a {
        text-decoration: none;
        color: #341210;
        font-size: 20px;

        &::after {
          content: '\203A';
          padding-left: 5px;
        }
      }
    }
  }
}

form {
  input {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #341210;
    border-radius: 5px;
    color: #341210;
    font-size: 18px;
  }

  .button {
    font-size: 18px;
    padding: 10px 30px;
    background-color: #341210;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
}

.submitting {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.97);
  z-index: 9999;
  display: flex;
  justify-content: center;

  img {
    width: 50px;
  }
}
</style>
