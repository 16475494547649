<template>
  <div id="app">
    <div :class="message ? 'warning' : ''">
      <p class="warn">{{message}}</p>
    </div>
    <router-view />
  </div>
</template>

<script>

  export default {
    name: 'App',
    data() {
      return {
        message: null
      }
    },
    onIdle() {
      this.message = 'Are you still there? After 5 minutes of inactivity the application will be restarted'
      setTimeout(() => {
        window.location.href = '/'
      }, 5000)
    },
    onActive() {
      this.message = null
    }
  }
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@400;700&display=swap');
* {
  box-sizing: border-box;
  font-family: 'Oswald',   sans-serif;
}

body {
  margin: 0;
  padding: 0;
}

.warning {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 10000;

  p {
    font-size: 20px;
    color: #341210;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: 0 auto;
    transform: translateY(-50%);
    margin: 0;
    padding: 0;
  }
}

.warn {
  margin: 0;
  padding: 0;
}
</style>
