import Vue from 'vue'
import VueAxios from 'vue-axios';
import axios from 'axios';
import Vuelidate from 'vuelidate';
import router from './router'
import App from './App.vue'
import IdleVue from 'idle-vue'
import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'

Vue.config.productionTip = false

Vue.use(VueAxios, axios);

Vue.use(VuePlyr)

Vue.use(Vuelidate);

const eventsHub = new Vue()

Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  idleTime: 300000
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
