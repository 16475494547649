<template>
  <div class="pdf">
    <a href="#" class="close" @click="goback">
      <svg aria-hidden="true" focusable="false" data-prefix="fad" data-icon="times-circle" class="svg-inline--fa fa-times-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><g class="fa-group"><path class="fa-secondary" fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1a12 12 0 0 1 0 17L338 377.6a12 12 0 0 1-17 0L256 312l-65.1 65.6a12 12 0 0 1-17 0L134.4 338a12 12 0 0 1 0-17l65.6-65-65.6-65.1a12 12 0 0 1 0-17l39.6-39.6a12 12 0 0 1 17 0l65 65.7 65.1-65.6a12 12 0 0 1 17 0l39.6 39.6a12 12 0 0 1 0 17L312 256z" opacity="0.4"></path><path class="fa-primary" fill="currentColor" d="M377.6 321.1a12 12 0 0 1 0 17L338 377.6a12 12 0 0 1-17 0L256 312l-65.1 65.6a12 12 0 0 1-17 0L134.4 338a12 12 0 0 1 0-17l65.6-65-65.6-65.1a12 12 0 0 1 0-17l39.6-39.6a12 12 0 0 1 17 0l65 65.7 65.1-65.6a12 12 0 0 1 17 0l39.6 39.6a12 12 0 0 1 0 17L312 256z"></path></g></svg>
    </a>
    <div class="inner" v-if="val === 'spi'">
      <div v-for="(slide, i) in spi" :key="i">
        <img :src="require(`../assets/images/${slide}`)">
      </div>
    </div>
    <div class="inner" v-if="val === 'ph'">
      <div v-for="(slide, i) in ph" :key="i">
        <img :src="require(`../assets/images/${slide}`)">
      </div>
    </div>
    <div class="inner" v-if="val === 'ag'">
      <div v-for="(slide, i) in ag" :key="i">
        <img :src="require(`../assets/images/${slide}`)">
      </div>
    </div>
    <div class="inner" v-if="val === 'ai'">
      <div v-for="(slide, i) in ai" :key="i">
        <img :src="require(`../assets/images/${slide}`)">
      </div>
    </div>
    <div class="inner" v-if="val === 'benm'">
      <div v-for="(slide, i) in benm" :key="i">
        <img :src="require(`../assets/images/${slide}`)">
      </div>
    </div>
    <div class="inner" v-if="val === 'rti'">
      <div v-for="(slide, i) in rti" :key="i">
        <img :src="require(`../assets/images/${slide}`)">
      </div>
    </div>
    <div class="inner" v-if="val === 'ffg'">
      <div v-for="(slide, i) in ffg" :key="i">
        <img :src="require(`../assets/images/${slide}`)">
      </div>
    </div>
    <div class="inner" v-if="val === 'hi'">
      <div v-for="(slide, i) in hi" :key="i">
        <img :src="require(`../assets/images/${slide}`)">
      </div>
    </div>
    <div class="inner" v-if="val === 'ita'">
      <div v-for="(slide, i) in ita" :key="i">
        <img :src="require(`../assets/images/${slide}`)">
      </div>
    </div>
    <div class="inner" v-if="val === 'la'">
      <div v-for="(slide, i) in la" :key="i">
        <img :src="require(`../assets/images/${slide}`)">
      </div>
    </div>
    <div class="inner" v-if="val === 'mi'">
      <div v-for="(slide, i) in mi" :key="i">
        <img :src="require(`../assets/images/${slide}`)">
      </div>
    </div>
    <div class="inner" v-if="val === 'bli'">
      <div v-for="(slide, i) in bli" :key="i">
        <img :src="require(`../assets/images/${slide}`)">
      </div>
    </div>
    <div class="inner" v-if="val === 'monticello'">
      <div v-for="(slide, i) in monticello" :key="i">
        <img :src="require(`../assets/images/${slide}`)">
      </div>
    </div>
    <div class="inner" v-if="val === 'blanding'">
      <div v-for="(slide, i) in blanding" :key="i">
        <img :src="require(`../assets/images/${slide}`)">
      </div>
    </div>
    <div class="inner" v-if="val === 'bluff'">
      <div v-for="(slide, i) in bluff" :key="i">
        <img :src="require(`../assets/images/${slide}`)">
      </div>
    </div>
    <div class="inner" v-if="val === 'seedo'">
      <div v-for="(slide, i) in seedo" :key="i">
        <img :src="require(`../assets/images/${slide}`)">
      </div>
    </div>
  </div>
</template>

<script>

  export default {
    name: 'PDF',
    data() {
      return {
        spi: [
        'spi-01.jpg',
        'spi-02.jpg',
        'spi-03.jpg',
        'spi-04.jpg',
        'spi-05.jpg',
        'spi-06.jpg',
        'spi-07.jpg',
        'spi-08.jpg',
        'spi-09.jpg',
        'spi-10.jpg',
        'spi-11.jpg',
        'spi-12.jpg',
        'spi-13.jpg',
        'spi-14.jpg',
        'spi-15.jpg'
        ],
        ph: [
        'ph-1.jpg',
        'ph-2.jpg'
        ],
        ag: [
        'ag-1.jpg',
        'ag-2.jpg'
        ],
        ai: [
        'ai-1.jpg',
        'ai-2.jpg'
        ],
        benm: [
        'benm-1.jpg',
        'benm-2.jpg',
        'benm-3.jpg'
        ],
        rti: [
        'rti-1.jpg',
        'rti-2.jpg',
        'rti-3.jpg'
        ],
        ffg: [
        'ffg-1.jpg',
        'ffg-2.jpg',
        'ffg-3.jpg'
        ],
        hi: [
        'hi-1.jpg',
        'hi-2.jpg',
        'hi-3.jpg'
        ],
        ita: [
        'ita-1.jpg',
        'ita-2.jpg',
        'ita-3.jpg'
        ],
        la: [
        'la-1.jpg',
        'la-2.jpg',
        'la-3.jpg'
        ],
        mi: [
        'mi-1.jpg',
        'mi-2.jpg',
        'mi-3.jpg'
        ],
        bli: [
        'bli-1.jpg',
        'bli-2.jpg',
        'bli-3.jpg'
        ],
        monticello: [
        'monticello_01.jpg',
        'monticello_02.jpg'
        ],
        blanding: [
        'blanding_01.jpg',
        'blanding_02.jpg'
        ],
        bluff: [
        'bluff_01.jpg',
        'bluff_02.jpg'
        ],
        seedo: [
        'see_do_1.jpg',
        'see_do_2.jpg'
        ]
      }
    },
    props: {
      val: String
    },
    methods: {
      goback() {
        this.$router.push({name:'main'});
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.pdf {
  background-image: url('../assets/images/page-bg.jpg');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  height: auto;
  .inner {
    width: 90%;
    max-width: 1100px;
    margin: 0 auto;
    height: auto;
    -webkit-box-shadow: 0 0 15px 1px rgba(0,0,0,0.5);
    box-shadow: 0 0 15px 1px rgba(0,0,0,0.5);

    img {
      width: 100%;
    }
  }
}

.close {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 5px;
  right: 5px;
  color: #fff;
}

</style>
